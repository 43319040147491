"use client"
import { ICustomImage } from "@filmweb/sanity-types";
import dynamic from 'next/dynamic';
import { useState } from "react";
import Button from "src/components/button/Button";
import CmsImage from "src/components/images/CmsImage";
import LoadingIndicator from "src/components/loadingindicator/LoadingIndicator";
import CameraSvg from "src/components/svg/CameraSvg";
import theme from "src/components/themes/DefaultTheme";
import styled from 'src/lib/styles/css';

const DynamicImageGalleryDisplay = dynamic(() => import('./ImageGalleryDisplay'), {
    loading: () => <LoadingIndicator />,
});

//#region [Props]
type ImageGalleryPlaceholderProps = {
    images?: ICustomImage[] | null;
    placeholderImage?: ICustomImage;
    placeholderWidth?: number;
    placeholderAspect?: number;
    placeholderSizes?: string;
    placeholderUnoptimized?: boolean;
    placeholderPriority?: boolean;
};
//#endregion

//#region [Component]
export default function ImageGalleryPlaceholder({ images, placeholderImage, placeholderWidth, placeholderAspect, placeholderSizes, placeholderUnoptimized, placeholderPriority }: ImageGalleryPlaceholderProps) {
    const [showGalleryDialog, setShowGalleryDialog] = useState(false);
    if ((images?.length ?? 0) < 2) {
        return null;
    }

    return <>
        <SImageGalleryContainer>
            <SPlaceholderImage image={placeholderImage!} alt="Se bildeserie" sizes={placeholderSizes} width={placeholderWidth} nonPosterAspect={placeholderAspect} unoptimized={placeholderUnoptimized} priority={placeholderPriority} />
            <SShowButton icon={<SCameraSvg />} text="Se bildegalleri" onClick={() => setShowGalleryDialog(true)} />

        </SImageGalleryContainer>
        {showGalleryDialog && <DynamicImageGalleryDisplay
            images={images!}
            onClose={() => setShowGalleryDialog(false)} />}
    </>;
}
//#endregion

//#region [Styles]
const SImageGalleryContainer = styled.div`
	position: relative;
	container-type: inline-size;
	margin: 0 ${theme.maxContentPadding} 35px ${theme.maxContentPadding};
	@media ${theme.mq.desktop} {
		//@media #{settings.$desktop} {//@media (orientation: landscape) {
		margin: 0;
		grid-area: gallery;
	}
`;

const SPlaceholderImage = styled(CmsImage)`
	border-radius: 8px;
`;

const SShowButton = styled(Button)`
	position: absolute;
	height: 46px;
	top: 50%;
	top: 25cqi;
	left: 50%;
	transform: translate(-50%, -50%);

	@supports(backdrop-filter: blur(15px)) {
		background-color: rgba(62, 35, 64, 0.5);
		backdrop-filter: blur(15px);
	}

`;

const SCameraSvg = styled(CameraSvg)`
	width: 20px;
	margin-right: 10px;
`;
//#endregion