import { getButtonCss } from "src/components/button/Button";
import theme from "src/components/themes/DefaultTheme";
import styled from 'src/lib/styles/css';

//#region [Props]
type StyleCenteredProps = {
};
//#endregion

//#region [Component]
export default function StyleCentered(props: any) {
	return <SStyleCentered>{props.children}</SStyleCentered>;
}
//#endregion

//#region [Styles]
const SStyleCentered = styled.p`
	text-align: center;

    a:first-child:last-child {
        --buttontextcolor: ${theme.bgColor};

        text-decoration: none;

        ${getButtonCss("round")}

        background: ${theme.palette.conicGradient};

    }
`;
//#endregion