import { ICustomImage } from "@filmweb/sanity-types";
import ImageGalleryPlaceholder from "src/components/imagegallery/ImageGalleryPlaceholder";
import { IFilmomtale } from "src/lib/types/Filmomtale";
import IGallery from "src/lib/types/IGallery";


//#region [Props]
type InlineGalleryProps = {
    value: {
        gallery: IGallery | IFilmomtale
    }
};
//#endregion

//#region [Component]
export default function InlineGallery({ value: { gallery } }: InlineGalleryProps) {
    let _images: ICustomImage[] | undefined;
    let _placeholderImage: ICustomImage | undefined;
    switch (gallery._type) {
        case "filmomtale":
            _images = (gallery?.imagesOverrideV2?.length ?? 0) > 0 ? gallery?.imagesOverrideV2 : gallery?.imagesV2;
            _placeholderImage = gallery?.mainImageGalleryV2 ?? gallery?.imagesOverrideV2?.[0] ?? gallery?.imagesV2?.[0];
            break;
        case "gallery":
            _images = gallery.images;
            _placeholderImage = gallery.images?.[0];
            break;
    }
    return <ImageGalleryPlaceholder placeholderImage={_placeholderImage} placeholderUnoptimized placeholderAspect={2 / 1} placeholderSizes="(min-width: 1921px) 786px, (max-width: 1920px) 555px, 98vw" images={_images} />
}
//#endregion

//#region [Styles]

//#endregion