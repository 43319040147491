"use client"
import FilmwebLink from "src/components/filmweblink/FilmwebLink";
import LoadingIndicator from "src/components/loadingindicator/LoadingIndicator";
import NoProviders from "src/components/streaming/NoProviders";
import StreamingProvider from "src/components/streaming/StreamingProvider";
import theme from "src/components/themes/DefaultTheme";
import { gql } from "graphql-request";
import { useFilminfo } from "src/lib/client/useFilminfo";
import styled from "src/lib/styles/css";
import { uniqBy } from "lodash";
import { useMemo } from "react";

//#region [Styles]
const SStreamingProviderList = styled.div`
	font-size: 18px;
	font-weight: bold;
	margin: 1em 0 2em 0;

`;

const SLabelLink = styled(FilmwebLink)`
	text-decoration: none;
	color: var(--textcolor) !important;
`;

const SProviderContainer = styled.div`
	overflow-x: hidden;
	display: flex;
	flex-wrap: wrap;
	gap: 20px;
`;

const SStreamingProvider = styled(StreamingProvider)`
	flex: 0 0 auto;
	width: calc(100% / 2 - 10px);

	@media ${theme.mq.desktop} {
		width: calc(100% / 3 - 40px / 3);
	}
`;
//#endregion



//#region [Props]
type StreamingProvidersProps = {
	value: {
		streamingId: number;
	}
	className?: string;
};
//#endregion

//#region [Component]
export default function StreamingProviders({ value: { streamingId }, className }: StreamingProvidersProps) {
	const result = useFilminfo(QUERY, { streamingIds: [streamingId] }, { active: !!streamingId });
	const providerList = useMemo(() => {
		const providers = result.fiData?.streamingQuery?.getStreamingContentList?.[0];
		if ((providers?.providerContents?.length ?? 0) > 0) {
			return uniqBy(providers!.providerContents, p => p?.id);
		}
		return [];
	}, [result.fiData?.streamingQuery?.getStreamingContentList]);

	if (!streamingId) return null;

	const isSeries = result.fiData?.streamingQuery?.getStreamingContentList?.[0]?.isSeries;
	const href = `/streamingguide/${isSeries ? "serie" : "film"}/${streamingId}`;
	const title = result.fiData?.streamingQuery?.getStreamingContentList?.[0]?.title;



	return <SStreamingProviderList className={className}>
		{result.fiLoading && <LoadingIndicator size="large" position="InlineCenter" />}
		{!result.fiLoading && result.fiData && (providerList?.length ?? 0) > 0 && <>
			<SLabelLink to={href}>{`Se hvor du kan streame ${title ?? (isSeries ? "serien" : "filmen")}`}</SLabelLink>
			<SProviderContainer>
				{providerList.map((p) => <SStreamingProvider key={p?.id} providerContent={p!} />)}
			</SProviderContainer>
		</>}
	</SStreamingProviderList>;
}
//#endregion

//#region [Other]
const QUERY = gql`
query ($streamingIds: [Int]!) {
	streamingQuery {
		getStreamingContentList(ids: $streamingIds) {
			__typename
			id
			isSeries
			title
			providerContents {
				__typename
		  		provider {
					id
					logo
					name
		  		}
		  		url
		  		id
		  		title
			}
		}
	}
}
`;
//#endregion