"use client"
import LoadingIndicator from "src/components/loadingindicator/LoadingIndicator";
import theme from "src/components/themes/DefaultTheme";
import useIsConsentReady from "src/lib/client/useIsConsentReady";
import styled from "src/lib/styles/css";

//#region [Props]
type EmbedConsentPlaceholderProps = {
    consentNeeded?: "preferences" | "statistics" | "marketing";
    className?: string;
};
//#endregion

//#region [Component]
export default function EmbedConsentPlaceholder({ consentNeeded = "marketing", className }: EmbedConsentPlaceholderProps) {
    const {isConsentReady, resetConsentStatus} = useIsConsentReady(consentNeeded);

    let _cookiesNeeded = "markedsførings";
    switch (consentNeeded) {
        case "preferences":
            _cookiesNeeded = "preferanse";
            break;
        case "statistics":
            _cookiesNeeded = "statistikk";
            break;
    }

    return <SEmbedConsentPlaceholder onClick={resetConsentStatus} className={className}>
        <SConsentNeededTrigger >
            For å se dette innholdet må {_cookiesNeeded}-cookies være slått på. Klikk her for å endre dine innstillinger.
            { typeof isConsentReady === "undefined" && <LoadingIndicator size="large" position="AbsoluteCenter" label="Venter på cookies"/>}
        </SConsentNeededTrigger>
    </SEmbedConsentPlaceholder>;
}
//#endregion

//#region [Styles]
const SEmbedConsentPlaceholder = styled.div`
    aspect-ratio: 16 / 9;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    background-color: ${theme.palette.lysGrå};
    cursor: pointer;
    padding: 1em;
`;

const SConsentNeededTrigger = styled.div`
    color: ${theme.textColorDark} !important;
    position: relative;
    flex: 1 1 auto;
`;
//#endregion