"use client";
import { useCallback, useEffect, useMemo, useState } from "react";

export default function useIsConsentReady(consentNeeded: "preferences" | "statistics" | "marketing" = "marketing"): {isConsentReady: boolean | undefined; resetConsentStatus: () => void} {
    const [isConsentReady, setConsentReady] = useState<boolean|undefined>(undefined);

    const resetConsentStatus = useCallback(() => {
        setConsentReady(undefined);
        window?.Cookiebot?.renew();
    }, [])

    useEffect(() => {
        let onConsentReady: () => void | undefined;

        switch(window?.Cookiebot?.consent?.[consentNeeded]) {
            case true:
                setConsentReady(true);
                break;
            case false:
                setConsentReady(false);
                break;
            default:
                onConsentReady = () => {
                    if (window?.Cookiebot?.consent?.[consentNeeded]) {
                        setConsentReady(true);
                    } else if( window?.Cookiebot?.consent?.[consentNeeded] === false) {
                        setConsentReady(false);
                    }

                }
                window.addEventListener("CookiebotOnConsentReady", onConsentReady);
        }

        return () => {
            if (onConsentReady) {
                window.removeEventListener("CookiebotOnConsentReady", onConsentReady);
            }
        }
    }, [consentNeeded]);
    return {isConsentReady, resetConsentStatus};

}