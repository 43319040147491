import { Review } from '@filmweb/sanity-types';
import { ReactNode } from "react";
import DiceValueSvg from 'src/components/diceratings/DiceValueSvg';
import LinkSvg from "src/components/svg/LinkSvg";
import theme from 'src/components/themes/DefaultTheme';
import styled from 'src/lib/styles/css';

//#region [Props]
type DiceRatingsProps = {
    reviews?: Review[];
    className?: string;
};
//#endregion

//#region [Component]
export default function DiceRatings({ reviews, className }: DiceRatingsProps) {
    if ((reviews?.length ?? 0) === 0) {
        return null;
    }
    return <SDiceContainer className={className}>
        {reviews!.map((dice, index) => {
            const valueNum: number = Number(dice.diceValue);
            if (!Number.isNaN(valueNum) && valueNum < 4) {
                return null;
            }

            let CustomValueIcon: ReactNode;
            if (Number.isNaN(valueNum)) {
                if (dice.diceValue === "0/0") {
                    CustomValueIcon = <SLinkSvg />
                } else {
                    CustomValueIcon = <SCustomRatingsValue>{dice.diceValue}</SCustomRatingsValue>;
                }
            }

            return (<SDiceValue key={index}>
                <SExtLink href={dice.reviewUrl} target='_blank'>
                    <div>
                        {!Number.isNaN(valueNum) ? <DiceValueSvg value={valueNum} /> : CustomValueIcon}
                    </div>
                    <div>
                        <SDiceText>{dice.who}</SDiceText>
                    </div>
                </SExtLink>
            </SDiceValue>);
        })}
    </SDiceContainer>;
}
//#endregion

//#region [Styles]
const SDiceContainer = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	padding: 25px 0 10px 0;
	grid-row-gap: 30px;
    justify-items: center;

	@media ${theme.mq.desktop} {
		grid-template-columns: 1fr 1fr 1fr 1fr;
        padding: 50px 0 40px 0;
	}
`;

const SDiceValue = styled.div`
	fill: var(--DICECOLOR-OVERRIDE, #eb1f59);
	color: var(--DICETEXTCOLOR-OVERRIDE, var(--buttontextcolor));
	font-size: 12px;
	text-align: center;

	@media ${theme.mq.desktop} {//@media (orientation: landscape) {
		font-size: 14.5px;
	}
`;

const SExtLink = styled.a`
	text-decoration: none;
	color: var(--DICETEXTCOLOR-OVERRIDE, var(--buttontextcolor));
`;

const SDiceText = styled.div`
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;

const SCustomRatingsValue = styled.div`
	color:  var(--DICECOLOR-OVERRIDE, #eb1f59);
	font-size: 24px;
	line-height: 1;
	font-weight: bold;
	@media ${theme.mq.desktop} {
		font-size: 30px;
		line-height: 41px;
	}

`;

const SLinkSvg = styled(LinkSvg)`
    --ICONCOLOR_OVERRIDE: #eb1f59;
    height: 40px;
    margin: 0 auto;
    display: block;
`;
//#endregion