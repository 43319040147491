import RichText from 'src/components/richtext/Richtext';
import IQuote from 'src/lib/types/Quote';
import styled from "src/lib/styles/css";

const CSS_MainColor = "#352761";
const CSS_ButtonColor = "#352761";

/**
 * Markup har blitt lånt herfra:
 * https://css-tricks.com/quoting-in-html-quotations-citations-and-blockquotes/
 **/

//#region [Styles]
const SQuoteContainer = styled.figure`
	&::after {
		content: none;
	}

	&:before {
		content: "";
		position: absolute;
		width: 8px;
		background: ${CSS_MainColor};
		//background: linear-gradient(180deg, ${CSS_ButtonColor} 0%, ${CSS_MainColor} 100%); // no effect since main and button use same color
		left: 0;
		top: 0;
		height: 100%;
		border-radius: 4px;
	}

	position: relative;
	margin-bottom: 1em;
	margin-top: 1em;
	display: block;
	margin-left: 25px;
	padding-left: 20px;

	font-style: italic;
`;

const SQuoteText = styled.blockquote`
	margin: 0;
`;

const SQuoteSource = styled.figcaption`
	text-align: right;
`;
//#endregion

//#region [Props]
type QuoteProps = {
    value: IQuote;
};
//#endregion

//#region [Component]
export default function Quote({ value }: QuoteProps) {
    return <SQuoteContainer>
        <SQuoteText><RichText disableCustomComponents value={value.quoteText} /></SQuoteText>
        {value.sourceV2 && <SQuoteSource>- {value.sourceV2}</SQuoteSource>}
    </SQuoteContainer>;
}
//#endregion