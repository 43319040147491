import { Twitter } from "@filmweb/nextjs";
import EmbedConsentPlaceholder from "src/components/richtext/EmbedConsentPlaceholder";
import useIsConsentReady from "src/lib/client/useIsConsentReady";
import styled from "src/lib/styles/css";

//#region [Props]
type TwitterProps = React.ComponentProps<typeof Twitter>
//#endregion

//#region [Component]
export default function TwitterWithConsent(props: TwitterProps) {
    const { isConsentReady } = useIsConsentReady();

    if (!isConsentReady) {
        return <SEmbedConsentPlaceholder />;
    }

    return <Twitter {...props} />;
}
//#endregion

//#region [Styles]
const SEmbedConsentPlaceholder = styled(EmbedConsentPlaceholder)`
    margin: 1em auto;
    max-width: 550px;
    aspect-ratio: 1;
`;
//#endregion