import { StandardSvgProps } from 'src/components/svg/StandardSvgProps';
import { SDefaultSvg, SFilledPath } from 'src/components/svg/SvgStyles';

//#region [Props]
type CameraSvgProps = {
} & StandardSvgProps;
//#endregion

//#region [Component]
export default function CameraSvg({ className }: CameraSvgProps) {
	return (<SDefaultSvg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26.521 17.68">
		<SFilledPath
			$isFilled
			d="M334.333,1299.68H310.18A1.183,1.183,0,0,1,309,1298.5V1285.45a1.182,1.182,0,0,1,1.184-1.181h.9a.8.8,0,0,1,.71-.425h2.273a.8.8,0,0,1,.71.425H316l.906-1.491a2.072,2.072,0,0,1,1.658-.778H325.9a2.072,2.072,0,0,1,1.658.778l.906,1.491h5.865a1.182,1.182,0,0,1,1.184,1.181V1298.5A1.183,1.183,0,0,1,334.333,1299.68ZM322.4,1285.707a5.988,5.988,0,1,0,5.988,5.988A5.988,5.988,0,0,0,322.4,1285.707Zm0,10.551a4.563,4.563,0,1,1,4.563-4.562A4.563,4.563,0,0,1,322.4,1296.258Z" transform="translate(-308.996 -1282)"
		/>
	</SDefaultSvg>)
}
//#endregion