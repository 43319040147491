import DiceRatings from "src/components/diceratings/DiceRatings";
import theme from "src/components/themes/DefaultTheme";
import styled from "src/lib/styles/css";
import { IFilmomtale } from "src/lib/types/Filmomtale";

//#region [Props]
type DiceRowProps = {
    value: {
        film: IFilmomtale
    }
};
//#endregion

//#region [Component]
export default function DiceRow({ value: { film: { reviews } } }: DiceRowProps) {
    return <SDiceRatings reviews={reviews} />;
}
//#endregion

//#region [Styles]
const SDiceRatings = styled(DiceRatings)`
    a {
        color: ${theme.palette.hvitHovedfarge} !important;
    }
`;
//#endregion