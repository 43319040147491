import theme from 'src/components/themes/DefaultTheme';
import styled from 'src/lib/styles/css';

//#region [Props]
type DiceValueSvgProps = {
	value: number;
};
//#endregion

//#region [Component]
export default function DiceValueSvg({ value }: DiceValueSvgProps) {
	if (value < 4) {
		return null;
	}
	return <SSvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 46 46">
		{value === 4 && <SPath d="M1681,888h-34a6,6,0,0,1-6-6V848a6,6,0,0,1,6-6h34a6,6,0,0,1,6,6v34A6,6,0,0,1,1681,888Zm-29-7a4,4,0,1,0-4-4A4,4,0,0,0,1652,881Zm0-32a4,4,0,1,0,4,4A4,4,0,0,0,1652,849Zm24,0a4,4,0,1,0,4,4A4,4,0,0,0,1676,849Zm0,24a4,4,0,1,0,4,4A4,4,0,0,0,1676,873Z" transform="translate(-1641 -842)" />}
		{value === 5 && <SPath d="M1611,888h-34a6,6,0,0,1-6-6V848a6,6,0,0,1,6-6h34a6,6,0,0,1,6,6v34A6,6,0,0,1,1611,888Zm-29-7a4,4,0,1,0-4-4A4,4,0,0,0,1582,881Zm0-32a4,4,0,1,0,4,4A4,4,0,0,0,1582,849Zm12,12a4,4,0,1,0,4,4A4,4,0,0,0,1594,861Zm12-12a4,4,0,1,0,4,4A4,4,0,0,0,1606,849Zm0,24a4,4,0,1,0,4,4A4,4,0,0,0,1606,873Z" transform="translate(-1571 -842)" />}
		{value === 6 && <SPath d="M1541,888h-34a6,6,0,0,1-6-6V848a6,6,0,0,1,6-6h34a6,6,0,0,1,6,6v34A6,6,0,0,1,1541,888Zm-29-39a4,4,0,1,0,4,4A4,4,0,0,0,1512,849Zm0,12a4,4,0,1,0,4,4A4,4,0,0,0,1512,861Zm0,12a4,4,0,1,0,4,4A4,4,0,0,0,1512,873Zm24-24a4,4,0,1,0,4,4A4,4,0,0,0,1536,849Zm0,12a4,4,0,1,0,4,4A4,4,0,0,0,1536,861Zm0,12a4,4,0,1,0,4,4A4,4,0,0,0,1536,873Z" transform="translate(-1501 -842)" />}
	</SSvg>;
}
//#endregion

//#region [Styles]
const SSvg = styled.svg`
	display: block;
	margin: 0 auto;
	width: 40px;

`;

const SPath = styled.path`
	fill: var(--DICECOLOR-OVERRIDE, #eb1f59);
`;
//#endregion