import { StandardSvgProps } from 'src/components/svg/StandardSvgProps';
import { SDefaultSvg, SFilledPath } from 'src/components/svg/SvgStyles';
import theme from 'src/components/themes/DefaultTheme';
import styled from "src/lib/styles/css";

//#region [Styles]
const SLinkSvg = styled(SDefaultSvg)`
	fill: none;

	path {
		fill: var(--ICONCOLOR_OVERRIDE, ${theme.palette.turkis2});
	}
`;

//#endregion

//#region [Props]
type LinkSvgProps = {
} & StandardSvgProps;

//#endregion

//#region [Component]
export default function HeartSvg({ className }: LinkSvgProps) {
    return (
        <SLinkSvg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 39.372 36.976">
            <g id="Group_648">
                <path id="Path_224" d="M262.413,100.324l.011-.008,0-.007Z" transform="translate(-243.025 -92.898)" />
                <path id="Path_225" d="M19.911,166.594a1.96,1.96,0,0,0-.484-.133c-.583-.166-1.163-.359-1.737-.557a9.618,9.618,0,0,1-2.117-1.046c-.127-.077-.277-.2-.432-.161a.669.669,0,0,0-.256.183c-.935.933-1.875,1.862-2.8,2.805a6.213,6.213,0,0,1-.727.666,4.084,4.084,0,0,1-5.8-.832A4.179,4.179,0,0,1,5,164.127a4.038,4.038,0,0,1,.977-1.679c2.621-2.642,5.26-5.26,7.886-7.905a5.24,5.24,0,0,1,2.037-1.434,4.132,4.132,0,0,1,4.108.934,3.253,3.253,0,0,0,.57.435,2.434,2.434,0,0,0,2.739-3.991,8.84,8.84,0,0,0-11.742-.421c-1.22,1.079-2.317,2.29-3.487,3.424L6.3,155.275l-2.97,2.965a9.824,9.824,0,0,0-2.977,4.55,8.847,8.847,0,0,0,8.016,11.266,8.954,8.954,0,0,0,4.657-1.029,9.379,9.379,0,0,0,2.406-1.849c1.463-1.457,2.909-2.943,4.4-4.372.058-.055.143-.156.085-.207Z" transform="translate(0 -137.103)"/>
                <path id="Path_226" d="M230.866,8.231A8.911,8.911,0,0,0,225.245.6,10.986,10.986,0,0,0,222.1,0a9.042,9.042,0,0,0-5.024,1.543,7.718,7.718,0,0,0-1.184.993q-1.146,1.123-2.28,2.257c-.862.836-1.68,1.725-2.57,2.527-.1.09-.082.176.06.234.735.208,1.493.387,2.212.661a10.7,10.7,0,0,1,2.066,1.055c.117.075.265.183.4.124a.794.794,0,0,0,.235-.183c.833-.836,1.679-1.657,2.5-2.5a4.465,4.465,0,0,1,4.187-1.761,3.934,3.934,0,0,1,3.324,3.407,3.668,3.668,0,0,1-.664,2.744,9.819,9.819,0,0,1-.937,1.076q-3.457,3.471-6.917,6.928a7.034,7.034,0,0,1-2.3,1.8,4.134,4.134,0,0,1-3.927-.5,6.3,6.3,0,0,0-1.063-.764A2.44,2.44,0,0,0,206.9,21.17a2.342,2.342,0,0,0,.487,2.257,8.385,8.385,0,0,0,2.451,1.77,8.845,8.845,0,0,0,5.818.675,9.516,9.516,0,0,0,4.939-3.029l3.528-3.526q1.362-1.362,2.721-2.727a25.342,25.342,0,0,0,2.553-2.791,8.835,8.835,0,0,0,1.474-5.553Z" transform="translate(-191.513)"/>
                <path id="Path_227" d="M262.52,100.108l-.007.015.011-.008,0-.007" transform="translate(-243.118 -92.712)" />
            </g>
        </SLinkSvg>
    );
}
//#endregion