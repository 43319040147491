import FilmwebLink from 'src/components/filmweblink/FilmwebLink';
import RichText from 'src/components/richtext/Richtext';
import theme from 'src/components/themes/DefaultTheme';
import styled, { css } from 'src/lib/styles/css';
import Color from 'src/lib/types/Color';
import { IMessageBanner } from 'src/lib/types/Settings';

// TODO: Skal ligge på følgende steder:
/*
✓ Forsiden
✓ SG alle sider bortsett fra søk
✓ Kinoprogram
Filmomtale
Filmquiz
kommer
kinotoppen
trailere
*/

//#region [Props]
type InfoBoxProps = {
	messageBanner: IMessageBanner;
	className?: string;
};
//#endregion

//#region [Component]
export default function InfoBox({ messageBanner, className }: InfoBoxProps) {
	if (!messageBanner.messageBannerActive) {
		return null;
	}

	return <SInfoBoxWrapper className={className}>
		<BannerTypeSelector link={messageBanner.link} bgColor={messageBanner.bgColor} textColor={messageBanner.textColor}>
			<SDescripton><RichText value={messageBanner.messageText} /></SDescripton>
			{messageBanner.linkText && <SButton $bgColor={messageBanner.buttonBgColor} $textColor={messageBanner.buttonTextColor}>{messageBanner.linkText}</SButton>}
		</BannerTypeSelector>
	</SInfoBoxWrapper>;
}
//#endregion

//#region [Styles]

const SInfoBoxWrapper = styled.div`
	width: 100%;
	padding: 0;
	max-width: 1660px;
	margin: 0 auto;

	p {
		margin: 0;
	}
`;

const SDescripton = styled.div`
	padding: 10px;
    font-size: 16px;

	@media ${theme.mq.desktop} {
		padding: 25px;
        font-size: 22px;
	}
`;

type SButtonProps = {
	$bgColor?: Color;
	$textColor?: Color;
};
const SButton = styled.div<SButtonProps>`
	border-radius: 30px;
    padding: 5px 20px;
    margin: 10px;
	font-size: 14px;
    font-weight: bold;
    min-width: 90px;

	@media ${theme.mq.mobile} {
		display: none;
	}

	@media ${theme.mq.desktop} {
        padding: 8px 25px;
        font-size: 18px;
        min-width: 100px;
    }

	background-color: ${props => props.$bgColor ? props.$bgColor.hex : theme.palette.lillaMørkHovedfarge};
	color: ${props => props.$textColor ? props.$textColor.hex : "white"};

`;
//#endregion


//#region [Props]
type BannerTypeSelectorProps = {
	link?: string;
	bgColor?: Color;
	textColor?: Color;
	children: React.ReactNode;
}
//#endregion

//#region [Component]
function BannerTypeSelector({ link, children, bgColor, textColor }: BannerTypeSelectorProps) {
	if (!link?.trim()) {
		<SContentElem $bgColor={bgColor} $textColor={textColor}>{children}</SContentElem>
	}
	return <SContentLink to={link!} $bgColor={bgColor} $textColor={textColor}>{children}</SContentLink>
}
//#endregion

//#region [Styles]
const SContentCSS = css`
	border-radius: 10px;
	display: flex;
    align-items: center;
	justify-content: center;
`;

type SContentProps = {
	$bgColor?: Color;
	$textColor?: Color;
};

const SContentLink = styled(FilmwebLink) <SContentProps>`
	${SContentCSS}
	text-decoration: none;

	background-color: ${props => props.$bgColor ? props.$bgColor.hex : "#eb1e59"};
	color: ${props => props.$textColor ? props.$textColor.hex : "white"};
`;

const SContentElem = styled.div<SContentProps>`
	${SContentCSS}

	background-color: ${props => props.$bgColor ? props.$bgColor.hex : "#eb1e59"};
	color: ${props => props.$textColor ? props.$textColor.hex : "white"};
`;
//#endregion