import { PortableText, PortableTextComponents, PortableTextMarkComponentProps, PortableTextTypeComponent } from "@portabletext/react";

import InlineImage from "src/components/richtext/InlineImage";

import Quote from "src/components/richtext/Quote";
import StreamingProviders from "src/components/richtext/StreamingProviders";
import StyleCentered from "src/components/richtext/StyleCentered";
import Table from "src/components/richtext/Table";

import Twentythree from "src/components/richtext/Twentythree";

import _ from "lodash";
import Link from "next/link";
import { PortableTextBlock, PortableTextTextBlock } from "sanity";
import DiceRow from "src/components/richtext/DiceRow";
import FacebookWithConsent from "src/components/richtext/FacebookWithConsent";
import InlineGallery from "src/components/richtext/InlineGallery";
import TikTokWithConsent from "src/components/richtext/TikTokWithConsent";
import Youtube from "src/components/richtext/Youtube";
import { getUrlForDoctype } from "src/lib/types/sanity";
import InstagramWithConsent from "src/components/richtext/InstagramWithConsent";
import TwitterWithConsent from "src/components/richtext/TwitterWithConsent";

//#region [Props]
type RichTextProps = {
    value?: PortableTextBlock[],
    className?: string,
    disableLinks?: boolean; // for use when the rich text is itself with a link
    disableCustomComponents?: boolean; // for use when images should not be allowed
}
//#endregion

//#region [Component] RichText
export default function RichText({ value, className, disableLinks = false, disableCustomComponents = false }: RichTextProps) {
    if (!value || value.length === 0) {
        return null;
    }
    const components = buildComponents(disableLinks, disableCustomComponents);
    return <div className={className}><PortableText value={value} components={components} /></div>
}
//#endregion

//#region [Other] buildComponents
function buildComponents(disableLinks: boolean = false, disableCustomComponents: boolean = false): PortableTextComponents {

    return {
        types: disableCustomComponents ? TYPES_NO_CUSTOMCOMPONENTS : TYPES_FULL,
        marks: disableLinks ? MARKS_NO_LINKS : MARKS_LINKS,
        block: BLOCKS
    };
}
//#endregion

//#region [Other] TYPES_FULL
const TYPES_FULL: Record<string, PortableTextTypeComponent<any>> = {
    instagramEmbed: InstagramWithConsent,
    youtubeEmbed: Youtube,
    tiktokEmbed: TikTokWithConsent,
    quote: Quote,
    twitterEmbed: TwitterWithConsent,
    facebookEmbed: FacebookWithConsent,
    twentythreeEmbed: Twentythree,
    streamingProviders: StreamingProviders,
    inlineImage: InlineImage,
    table: Table,
    inlineGallery: InlineGallery,
    diceRow: DiceRow
};
//#endregion

const BLOCKS = {
    centered: StyleCentered
}

const COUNTABLE_TYPES = ["block", ..._.without(Object.keys(TYPES_FULL), "inlineImage")];

//#region [Other] NoRender
function NoRender() { return null; };

const TYPES_NO_CUSTOMCOMPONENTS: Record<string, PortableTextTypeComponent<any>> = {
    instagramEmbed: NoRender,
    youtubeEmbed: NoRender,
    tiktokEmbed: NoRender,
    quote: NoRender,
    twitterEmbed: NoRender,
    facebookEmbed: NoRender,
    twentythreeEmbed: NoRender,
    streamingProviders: NoRender,
    inlineImage: NoRender,
    table: NoRender,
    inlineGallery: NoRender,
    diceRow: NoRender
};

const MARKS_NO_LINKS = {
    streamingLink: ({ children, value }: PortableTextMarkComponentProps<any>) => {
        return <span>{children}</span>;
    },
    internalLink: ({ children, value }: PortableTextMarkComponentProps<any>) => {
        return <span>{children}</span>;
    },
    mailTo: ({ children, value }: PortableTextMarkComponentProps<any>) => {
        return <span>{children}</span>;
    },
    link: ({ children, value }: PortableTextMarkComponentProps<any>) => {
        return <span>{children}</span>;
    },
};

const MARK_TYPES = Object.keys(MARKS_NO_LINKS);

//#endregion

//#region [Other]
const MARKS_LINKS = {
    internalLink: ({ children, value }: PortableTextMarkComponentProps<any>) => {
        let url: string | null = null;
        if (value.personName) {
            url = `/navn/${encodeURI(value.personName.replaceAll(" ", "_"))}`
        } else {
            try {
                url = getUrlForDoctype(value.docType as string, value.slug as string, !!value.isStreaming);
            }
            catch (e) {
                console.error("Value", value);
            }
        }
        if (!url) {
            return <span>{children}</span>
        }
        return (
            <Link href={url}>
                {children}
            </Link>
        )
    },
    link: ({ children, value }: PortableTextMarkComponentProps<any>) => {
        if ((value.href as string)?.startsWith("https://www.filmweb.no")) {
            return (<Link href={(value.href as string).substring("https://www.filmweb.no".length)}>{children}</Link>);
        }
        return (<a href={value.href}>{children}</a>);
    },
    mailTo: ({ children, value }: PortableTextMarkComponentProps<any>) => {
        return (<Link href={`mailto:${value.mailto}`}>
            {children}
        </Link>);
    },
    streamingLink: ({ children, value }: PortableTextMarkComponentProps<any>) => {
        return (<Link href={`/streamingguide/${value.isSeries ? "serie" : "film"}/${value.streamingId}`}>
            {children}
        </Link>);
    },

};
//#endregion


//#region [Other]
/**
 * - Etter hver 4. sammenhengende paragraf
 *
 * - Før hver heading
 * 	- Forutsatt at det er 2 eller flere sammenhengende paragrafer før.
 * 		- MERK: Noen ganger ligger det et bilde rett før headingen. Da skal annonsen legges før bildet
 * @param text The unmodified text
 * @returns The blocks split into chunks with ads or something between
 */
export function splitBlocksForAds(text: PortableTextBlock[]): PortableTextBlock[][] {
    //console.group("SPLIT BLOCKS");
    let result: PortableTextBlock[][] = [];

    let paragraphCounter = 0;
    let paragraphCounterPrev = 0;
    let lastChunkEndIndex = 0;
    let listItemCounter = 0;
    let prevBlockWasInlineImage = false;
    let blockIndex = 0; // defined outside for loop since I need it later
    for (; blockIndex < text.length; blockIndex++) {
        const block = text[blockIndex];
        const blockType = block._type;
        const styleType = (text[blockIndex].style ?? "normal") as string;
        //console.debug("Paracounter", paragraphCounter, block);
        if (isSkippableBlock(block)) {
            // count streamingProviders as part of the paragraph before it
        } else if (paragraphCounter === 4 && isCountableBlock(block)) {
            result.push(text.slice(lastChunkEndIndex, blockIndex));
            lastChunkEndIndex = blockIndex;
            paragraphCounterPrev = paragraphCounter;
            paragraphCounter = 0;
        } else if (paragraphCounter >= 2 && /^h[1-6]$/i.test(styleType)) {
            if (blockIndex > 2) {// Hopp over annonse dersom denne headingen er for tidlig i artikkelen
                result.push(text.slice(lastChunkEndIndex, blockIndex));
                lastChunkEndIndex = blockIndex;
            }
            paragraphCounterPrev = paragraphCounter;
            paragraphCounter = 0;
        } else if (paragraphCounterPrev >= 2 && prevBlockWasInlineImage && /^h[1-6]$/i.test(styleType)) {
            if (blockIndex > 2) {// Hopp over annonse dersom denne headingen er for tidlig i artikkelen
                //console.debug(lastChunkEndIndex, blockIndex, text[blockIndex]);
                result.push(text.slice(lastChunkEndIndex, blockIndex - 1));
                lastChunkEndIndex = blockIndex - 1;
            }
            paragraphCounterPrev = paragraphCounter;
            paragraphCounter = 0;
        }

        if (isCountableBlock(block)) {
            prevBlockWasInlineImage = false;
            // quotes and other countable blocks does not reset the counter, but quotes does not increment.
            // lists only increment after all list items are done
            if (block.listItem) {
                listItemCounter++;
            } else if (listItemCounter > 0) {
                listItemCounter = 0;
                paragraphCounterPrev = paragraphCounter;
                paragraphCounter++;
            }

            if (blockType !== "quote" && !block.listItem) {
                paragraphCounterPrev = paragraphCounter;
                paragraphCounter++;
            }
        } else {
            paragraphCounterPrev = paragraphCounter;
            paragraphCounter = 0;
            if (blockType === "inlineImage") {
                prevBlockWasInlineImage = true;
            } else {
                prevBlockWasInlineImage = false;
            }
        }
    }

    if (blockIndex !== lastChunkEndIndex) {
        result.push(text.slice(lastChunkEndIndex));
    }
    //console.groupEnd();
    return result;
}

function isCountableBlock(block: PortableTextBlock): boolean {
    if (/^h[1-6]$/i.test(block.style as string ?? "")) {
        return false; // headings should not be countable
    }
    if (COUNTABLE_TYPES.includes(block._type)) {
        return true;
    }
    return false;
}

function isSkippableBlock(block: PortableTextBlock): boolean {
    switch (block._type) {
        case "streamingProviders":
            return true;
    }
    if (block._type === "block") {
        // If the block only contains a single link, it should not be considered countable unless the block is a heading
        const pttb = (block as PortableTextTextBlock);
        if (pttb.style === "normal" && pttb.children?.length === 1 && pttb.markDefs?.some(md => MARK_TYPES.includes(md._type))) {
            return true;
        }
    }

    return false;
}


//#endregion

//#region [Other]
/* Old streaming provider link:
{
  "children": [
    {
      "marks": [
        "JcfD9Uh3fy1Zb1b7Idl6U"
      ],
      "text": "Se hvor du kan streame den her.",
      "_key": "e647b1f1427f0",
      "_type": "span"
    }
  ],
  "_type": "block",
  "style": "normal",
  "_key": "e647b1f1427f",
  "markDefs": [
    {
      "_key": "JcfD9Uh3fy1Zb1b7Idl6U",
      "_type": "link",
      "href": "/streamingguide/serie/76392/Heksejakt/"
    }
  ]
}
*/

const SG_LINK_REGEX = /\/streamingguide\/(?:serie|film)\/(\d+)/i;

export function replaceOldStreamingProviderLinks(text: PortableTextBlock[]): PortableTextBlock[] {
    return text.map(ptb => {
        // do we have any marks with a streaming link?
        let streamingId: number = 0;
        const sgLinkMarkDef = (ptb as PortableTextTextBlock).markDefs?.some(markDef => {
            if (markDef?._type === "link" && markDef.href) {
                const linkMatch = SG_LINK_REGEX.exec(markDef.href as string);
                if (linkMatch) {
                    streamingId = Number(linkMatch[1]);
                    return true;
                }
                return false;
            }
            return false;
        });

        if (sgLinkMarkDef && streamingId > 0) {
            // we have a streaming link. Check if the text seems to be the old "Se hvor du kan streame den her"
            const isProviderLink = (ptb as PortableTextTextBlock).children?.some((ptts => {
                return ptts.text && (ptts.text as string).startsWith("Se hvor du kan streame");
            }));

            if (isProviderLink) {
                return {
                    _key: ptb._key,
                    _type: "streamingProviders",
                    streamingId: streamingId
                } as PortableTextBlock;
            }
        }

        return ptb;
    });
}
//#endregion

//#region [Other]
/**
 * Old content in Escenic often contained empty paragraphs (<p></p>).
 * If a "block" has no marks and only one child with empty text, remove it
 */
export function stripEmptyParagraphs(text: PortableTextBlock[]): PortableTextBlock[] {
    return text.filter(ptp => {
        if ("children" in ptp) {
            const block = ptp as PortableTextTextBlock;
            if ((block.markDefs?.length ?? 0) === 0 && block.children.length === 1 && block.children[0].text === "" && block.children[0]._type === "span") {
                return false;
            }
        }
        return true;
    });
}
//#endregion