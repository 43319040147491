import { ICustomImage } from '@filmweb/sanity-types';
import CmsImage from 'src/components/images/CmsImage';
import { getOrgSize } from "src/components/images/imageFunctions";
import ImageZoom from "src/components/imagezoom/ImageZoom";
import theme from 'src/components/themes/DefaultTheme';
import styled, { css } from "src/lib/styles/css";


//#region [Props]
type InlineImageProps = {
    value: {
        image: ICustomImage;
        imageText?: string;
    }
};
//#endregion

//#region [Component]
export default function InlineImage({ value: { image, imageText } }: InlineImageProps) {
    if (!image?.asset?._id) {
        console.warn("Image is missing", image);
        return null;
    }

    if (image?.asset?.isOkForCopyrightUse !== true) {
        //console.warn("Image is not copyright approved", _imgRef._id);
        return null;
    }

    const text = buildImageText(image, imageText);
    const altText = image?.asset?.altText ?? image?.asset?.title ?? "";
    const orgSize = getOrgSize(image.asset._id);

    if (text) {
        return <ImageZoom asset={image.asset}>
            <SFigure>
                <SInlineImage $aspect={orgSize.aspect} unoptimized={orgSize.aspect < 1} width={orgSize.aspect < 1 ? 740 : undefined} image={image} alt={altText} sizes={orgSize.aspect < 1 ? "370px" : '(min-width: 1921px) 786px, (max-width: 1920px) 555px, 98vw'} />
                <SFigcaption>{text}</SFigcaption>
            </SFigure>
        </ImageZoom>
    }
    return <ImageZoom asset={image.asset}>
        <SInlineImage $aspect={orgSize.aspect} unoptimized={orgSize.aspect < 1} width={orgSize.aspect < 1 ? 740 : undefined} image={image} alt={altText} sizes={orgSize.aspect < 1 ? "370px" : '(min-width: 1921px) 786px, (max-width: 1920px) 555px, 98vw'} />
    </ImageZoom>
}

function buildImageText(img: ICustomImage, imgText?: string): string {
    let text = "";
    if (imgText) {
        text = imgText;
    }
    if (img?.asset?.copyright?.trim()) {
        text += (text ? ", " : "") + "Foto: " + img.asset.copyright.trim();
    }
    return text.trim();
}
//#endregion

//#region [Styles]
type SInlineImagePprops = {
    $aspect: number;
}

const SInlineImage = styled(CmsImage) <SInlineImagePprops>`
	height: auto;
	display: block;

    ${p => p.$aspect < 1 ? css`
        max-width: 370px;
        margin: 0 auto;
    `: ""}

`;

const SFigure = styled.figure`
	margin: 0 0 1em 0;

`;

const SFigcaption = styled.figcaption`
	font-size: ${theme.fonts.bodytextS.size};
`;
//#endregion