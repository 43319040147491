"use client"
import { ICustomImageAsset } from "@filmweb/sanity-types";
import { ReactNode, useState } from "react";
import FullPageOverlay from "src/components/fullpageoverlay/FullPageOverlay";
import CmsImageForAssetRef from "src/components/images/CmsImageForAssetRef";
import { getOrgSize } from "src/components/images/imageFunctions";
import styled, { css } from "src/lib/styles/css";

//#region [Props]
type ImageZoomProps = {
    children: ReactNode;
    asset: ICustomImageAsset;
};
//#endregion

//#region [Component]
export default function ImageZoom({ children, asset }: ImageZoomProps) {
    const orgSize = getOrgSize(asset._id);
    const [isExpanded, setIsExpanded] = useState(false);


    let w = Math.min(1920, orgSize.width);
    let h = w / orgSize.aspect;
    if (orgSize.aspect <= 1 && typeof window !== "undefined") {
        h = window?.innerHeight;
        w = h * orgSize.aspect;

    }


    return <div onClick={() => { !isExpanded ? setIsExpanded(true) : null }}>
        {children}
        {isExpanded && <FullPageOverlay onClose={() => setIsExpanded(false)} closeOnClick>
            <SCmsImageForAssetRef assetRef={asset._id} alt={asset.altText!} width={w} height={h} />
        </FullPageOverlay>}
    </div>;
}
//#endregion

//#region [Styles]
const SCmsImageForAssetRef = styled(CmsImageForAssetRef)`
    ${p => p.width! <= p.height! ? css`
        max-height: 100vh;
        width: auto;
        margin-right: auto;
        margin-left: auto;
    ` : undefined}


`;
//#endregion