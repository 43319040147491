import styled from "src/lib/styles/css";
import { KeyedObject, TypedObject } from "sanity";



//#region [Styles]
const STableWrapper = styled.div`
	max-width: 100%;
    overflow-x: auto;
`;

const STable = styled.table`
	border: 1px solid currentColor;
	border-collapse: collapse;
	font-size: 0.9em;
	table-layout: fixed;
`;

const STr = styled.tr`

`;

const STd = styled.td`
	border: 1px solid currentColor;
	padding: 0.5em;
`;
//#endregion

//#region [Props]
type TableProps = {
	value: TableObj;
	className?: string;
}

type TableObj = {
	rows: TableRowObj[];
} & KeyedObject & TypedObject;

type TableRowObj = {
	cells: string[];
} & KeyedObject & TypedObject;

//#endregion

//#region [Component]
export default function Table(props: TableProps) {
	if ((props.value?.rows?.length ?? 0) === 0) {
		return null;
	}

	return (<STableWrapper>
		<STable className={props.className}>
			<tbody>
				{props.value.rows.map((row) => (<STr key={row._key}>
					{row.cells.map((cell, cellIndex) => <STd key={`${row._key}${cellIndex}`}>{cell ? cell : '\u00A0'}</STd>)}
				</STr>))}
			</tbody>
		</STable>
	</STableWrapper>);

}
//#endregion